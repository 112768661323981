import { userStore } from 'stores';
import { observer } from 'mobx-react-lite';

import { Works } from './Works';
import { Login } from './Login';
import { MyWorks } from './MyWorks';

export const Home = observer(() => {
	const { isAuth, isAdmin } = userStore;
	return isAuth ?
		isAdmin ? <Works /> : <MyWorks />
		: <Login />;
});