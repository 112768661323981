import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableProps, Tag } from 'antd';
import { IAssignedWorker, IKeys, IUserRoles, IWork, IWorkState } from 'models';
import { MY_WORKS_ROUTE } from 'routes/consts';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { workService } from 'services';
import { useUser } from 'hooks';

export const AdminWorks = () => {
	const [page, setPage] = useState(1);
	const { getUser, isUsersLoading } = useUser();
	const { data, isLoading } = useQuery({
		queryKey: [IKeys.WORKS, { page, state: IWorkState.ASSIGN_WORKERS }],
		queryFn: () => workService.getAll({
			state: IWorkState.ASSIGN_WORKERS,
			page,
			orderby: 'date',
			order: 'desc',
			sortbydate: true
		})
	});

	const columns: TableProps<IWork>['columns'] = [
		{
			title: 'Order',
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`${MY_WORKS_ROUTE}/${id}`}>{id}</Link>
		},
		{
			title: 'Foreman',
			dataIndex: ['acf', 'foreman_info', 'workers'],
			key: 'worker',
			render: (workers: IAssignedWorker[]) => {
				const id = workers?.find((el) => el.worker_role === IUserRoles.FOREMAN)?.worker;
				return id?.name || getUser(Number(id))?.name;
			}
		},
		{
			title: 'Date',
			dataIndex: ['acf', 'date'],
			key: 'date',
			render: (date) => dayjs(date).format('MM/DD')
		},
		{
			title: 'Time',
			dataIndex: ['acf', 'customer_info', 'time'],
			key: 'time'
		},
		{
			title: 'Status',
			dataIndex: ['acf', 'foreman_info', 'status'],
			key: 'status',
			render: (status) => <Tag color={status === 'pending' ? 'orange' : 'green'}>{status}</Tag>
		}
	];
	return <Table
		loading={isLoading || isUsersLoading}
		rowKey={(el) => el.id}
		dataSource={data?.data}
		columns={columns}
		pagination={{
			current: page,
			pageSize: 10,
			total: data?.headers['x-wp-total'],
			onChange: (page) => setPage(page),
			showSizeChanger: false
		}}
	/>;
};
