import React, { useState } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import { IUserRoles, IWork, IWorkState, IWorker } from 'models';
import { useUser } from 'hooks';

const options = (arr: IWorker[]) => arr.map(el => ({ value: el.id, label: el.name || el.display_name }));

interface SelectForemanHelpersProps {
	form: FormInstance,
	work?: IWork | null
}

export const SelectForemanHelpers = ({ form, work }: SelectForemanHelpersProps) => {
	const { foremans, helpersForemans, isUsersLoading } = useUser();
	const [refWorkers, setRefWorkers] = useState<any>(work?.acf.foreman_info.workers);

	const setWorker = (worker_role: IUserRoles, worker: number) => {
		let newArr = [];
		if (refWorkers) {
			if (worker_role === IUserRoles.FOREMAN) {
				newArr = [...refWorkers.filter((el: any) => el?.worker_role === IUserRoles.HELPER), { worker, worker_role }];
			} else {
				newArr = [...refWorkers, { worker, worker_role }];
			}
		} else {
			newArr = [{ worker, worker_role }];
		}
		setRefWorkers(newArr);
		form.setFieldsValue({
			acf: {
				foreman_info: { workers: newArr },
				state: IWorkState.ASSIGN_WORKERS
			}
		});
	};

	const deleteWorker = (value: number) => {
		form.setFieldsValue({
			fields: { foreman_info: { workers: refWorkers?.filter((el: any) => el.worker !== value) } }
		});
	};

	const foremanRemove = () => {
		let newArr = refWorkers?.filter((el: any) => el.worker_role !== IUserRoles.FOREMAN);

		form.setFieldsValue({
			fields: {
				foreman_info: { workers: newArr },
				state: 'select'
			}
		});
	};

	return (
		<>
			<Form.Item name="foreman" label="Foreman">
				<Select
					onSelect={(value) => setWorker(IUserRoles.FOREMAN, value)}
					onClear={foremanRemove}
					options={options(foremans)}
					allowClear
					loading={isUsersLoading}
				/>
			</Form.Item>
			<Form.Item name="helpers" label="Helpers">
				<Select
					onDeselect={deleteWorker}
					onSelect={(value) => setWorker(IUserRoles.HELPER, value)}
					options={options(helpersForemans)}
					mode="multiple"
					allowClear
					loading={isUsersLoading}
				/>
			</Form.Item>

			<Form.List name={['acf', 'foreman_info', 'workers']}>
				{(fields) => fields.map(({ key }) => (
					<div key={key}>
						<Form.Item
							name={[key, 'worker']}
							key={key}
							hidden
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={[key, 'worker_role']}
							key={key}
							hidden
						>
							<Input />
						</Form.Item>
					</div>
				)
				)}
			</Form.List>
		</>
	);
};