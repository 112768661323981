import { makeAutoObservable } from 'mobx';

class Workers {
	data: null = null;

	constructor() {
		makeAutoObservable(this);
	}
}

export const workersStore = new Workers();