import { useQuery } from '@tanstack/react-query';
import { IKeys, IUserRoles } from 'models';
import { useMemo } from 'react';
import { userService } from 'services';
import { userStore } from 'stores';

export const useUser = () => {
	const { isLoading, data } = useQuery({
		queryKey: [IKeys.USERS],
		queryFn: () => userService.getAll({
			per_page: 100
		}),
		enabled: userStore.isAuth,
		staleTime: Infinity
	});
	const helpers = useMemo(() => data?.filter(el => el.roles.includes(IUserRoles.HELPER)) || [], [data]);
	const foremans = useMemo(() => data?.filter(el => el.roles.includes(IUserRoles.FOREMAN)) || [], [data]);
	const helpersForemans = [...helpers, ...foremans];
	const getUser = (id: string | number) => data?.find(user => user.id === id);
	return {
		allUsers: data,
		getUser,
		helpers,
		foremans,
		helpersForemans,
		isUsersLoading: isLoading
	};
};
