import { Button, Drawer, Flex, Form, Popconfirm } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { IWork } from 'models';
import dayjs from 'dayjs';
import { copyOrderInfo } from 'utils';
import { SlotInfo } from 'react-big-calendar';
import { useWorkMutation } from 'hooks';

import { WorkTitle } from './WorkTitle';
import { CreateWorkForm } from './forms';

interface MyModalProps {
	work: IWork | null,
	slot?: SlotInfo | null,
	handleCancel: () => void,
	queryKey: any[]
}

export const MyModal = ({ work, handleCancel, slot, queryKey }: MyModalProps) => {
	const [form] = Form.useForm<IWork>();
	const { updateWork, deleteWork, messageApi, contextHolder } = useWorkMutation({ queryKey, work, onSettled: () => handleCancel(), onSettledDelete: () => handleCancel() });

	const onFinish = (values: Partial<IWork>) => {
		if (values.acf) {
			values.acf.date = dayjs(values.acf.date).format('YYYYMMDD');
			updateWork.mutate(values);
		}
	};

	const onDeleteJob = () => {
		deleteWork.mutate();
	};

	const copyToClickBoard = () => {
		copy(copyOrderInfo(form.getFieldsValue()));
		messageApi.success('Copied!');
	};

	return (
		<Drawer
			destroyOnClose={true}
			title={work ? <WorkTitle work={work} /> : 'Add new work'}
			size="large"
			open={!!work || !!slot}
			onClose={handleCancel}
			footer={(
				<Flex gap={8}>
					<Button key="cancel" onClick={handleCancel} size="large">
						Cancel
					</Button>,
					<Popconfirm
						title="Are you sure to delete this job?"
						onConfirm={onDeleteJob}
						okText="Yes"
						cancelText="No"
						key="delete"
					>
						<Button loading={deleteWork.isPending} danger type="primary" size="large">
							Delete
						</Button>
					</Popconfirm>,
					<Button
						loading={updateWork.isPending}
						key="submit"
						type="primary"
						onClick={() => {
							form.submit();
						}}
						size="large"
					>
						Submit
					</Button>
				</Flex>
			)}
		>
			{work && (
				<Button
					onClick={copyToClickBoard}
					type="primary"
					style={{ width: '100%', marginBottom: 15 }}
					size="large"
					icon={<CopyOutlined />}
				>
					Copy order to clipboard
				</Button>
			)}
			<CreateWorkForm
				work={work}
				onFinish={onFinish}
				slot={slot}
				queryKey={queryKey}
				form={form}
			/>
			{contextHolder}
		</Drawer>
	);
};

