import { useCallback, useState } from 'react';
import { Button, Divider, Drawer, Table, TableProps, Tag } from 'antd';
import { userStore } from 'stores';
import { IKeys, IUserRoles, IWork } from 'models';
import { CreateCustomerForm, MyModal } from 'components';
import { useUser } from 'hooks';
import { useQuery } from '@tanstack/react-query';
import { workService } from 'services';
import dayjs from 'dayjs';
import { formatPhoneNumber } from 'utils';

export const Works = () => {
	const [isModal, setIsModal] = useState(false);
	const { getUser, isUsersLoading } = useUser();
	const [page, setPage] = useState(1);
	const [author, setAuthor] = useState<number | string | undefined>(userStore.isAdmin ? '' : userStore.data?.id);
	const { data, isPending } = useQuery({
		queryFn: () => workService.getAll({ page, author }),
		queryKey: [IKeys.WORKS, { page, author }]
	});

	const [work, setWork] = useState<null | IWork>(null);
	const openWorkDrawer = (values: IWork) => {
		setWork(values);
	};
	const handleCancel = useCallback(() => {
		setWork(null);
	}, []);

	const columns: TableProps<IWork>['columns'] = [
		{
			title: 'Name',
			dataIndex: ['acf', 'customer_info', 'customer_name'],
			key: 'name',
			render: (name, record) => <span style={{ color: '#40a9ff' }} onClick={() => openWorkDrawer(record)}>{name || 'undefined'}</span>
		},
		{
			title: 'Created',
			dataIndex: ['date'],
			key: 'created',
			render: date => dayjs(date).format('MM/DD')
		},
		{
			title: 'Move day',
			dataIndex: ['acf', 'date'],
			key: 'moveDate',
			render: date => dayjs(date).format('MM/DD')
		},
		{
			title: 'Phone',
			dataIndex: ['acf', 'customer_info', 'customer_phone'],
			key: 'phone',
			render: phone => <a style={{ minWidth: 120, display: 'block' }} href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
		},
		{
			title: 'Email',
			dataIndex: ['acf', 'customer_info', 'customer_email'],
			key: 'email',
			render: email => <a href={`mailto:${email}`}>{email}</a>
		},
		{
			title: 'Status',
			dataIndex: ['acf', 'state'],
			key: 'state',
			render: state => {
				if (state === 'pending') {
					return <Tag color="#f50">Pending</Tag>;
				} else {
					return <Tag color="#108ee9">Confirmed</Tag>;
				}

			}
		},
		{
			title: 'Manager',
			dataIndex: ['author'],
			key: 'author',
			render: id => getUser(id)?.name || getUser(id)?.display_name,
			filters: [{ text: 'Alex', value: 27 }, { text: 'Alena', value: 30 }],
			onFilter: (value) => {
				setAuthor(value as number);
				return true;
			}
		}
	];

	return (
		<>
			{(userStore.role === IUserRoles.ADMIN || userStore.role === IUserRoles.MANAGER) && (
				<>
					<Button onClick={() => setIsModal(true)} size="large" type="primary" block>
						Add customer
					</Button>
					<Divider />
					<Table
						loading={isPending || isUsersLoading}
						rowKey={record => record.id}
						columns={columns}
						dataSource={data?.data}
						pagination={{
							current: page,
							pageSize: 10,
							total: data?.headers['x-wp-total'],
							onChange: (page) => setPage(page),
							showSizeChanger: false
						}}
					/>
					<MyModal
						work={work}
						handleCancel={handleCancel}
						queryKey={[IKeys.WORKS, { page, author }]}
					/>
					<Drawer
						title="Add Customer"
						open={isModal}
						footer={null}
						onClose={() => setIsModal(false)}
						destroyOnClose={true}>
						<CreateCustomerForm
							queryKey={[IKeys.WORKS, { page, author }]}
							closeModal={() => setIsModal(false)}
						/>
					</Drawer>
				</>
			)}
		</>
	);
};
