import React from 'react';
import { Descriptions, Skeleton, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { suppliesPrice } from 'utils';
import { useQuery } from '@tanstack/react-query';
import { workService } from 'services';
import { IKeys } from 'models';
import { useUser } from 'hooks';

const { Item } = Descriptions;

export const CompletedWork = () => {
	const params = useParams<{ id: string }>();
	const { getUser, isUsersLoading } = useUser();
	const { data, isLoading } = useQuery({
		queryFn: () => workService.getOne(params?.id || 0),
		queryKey: [IKeys.WORKS, { id: params?.id }]
	});
	const info = data?.acf.foreman_info;

	const suppliesSum = (arr?: any[] | null) => {
		return arr?.reduce((acc: number, el: any) => {
			return acc + suppliesPrice(el.add_item.value) * el.add_item_count;
		}, 0) || 0;
	};

	const arrSum = (prop: string, arr?: any[] | null) => {
		return arr?.reduce((acc: number, el: any) => {
			return acc + +el[prop];
		}, 0) || 0;
	};

	return (!isLoading || !isUsersLoading) ? (
		<Space direction="vertical" style={{ width: '100%' }} size="large">
			<Descriptions size="small" title={`Job ${params.id}`} bordered>
				<Item label="Total">${info?.total}</Item>
				<Item label="Tips">${info?.tips}</Item>
				<Item label="Salary">${arrSum('salary', info?.workers)}</Item>
				<Item label="Balance">${Number(info?.total) - Number(info?.tips) - arrSum('salary', info?.workers)}</Item>
				<Item label="Supplies">${suppliesSum(info?.add_items)}</Item>
				<Item label="Heavy items">${arrSum('heavy_item_price', info?.heavy_items)}</Item>
				{info?.discount === 'yes' && <Item label="Discount">${info?.discount}</Item>}
				<Item label="Total time">{Math.round((info?.total_time || 0) * 10) / 10}h</Item>
			</Descriptions>
			{!!info?.add_items?.length && (
				<Descriptions size="small" title="Supplies" bordered>
					{info.add_items.map((el) => (
						<Item key={el.add_item} label={el.add_item}>
							${suppliesPrice(el.add_item) * el.add_item_count} / {el.add_item_count}
						</Item>
					))}
				</Descriptions>
			)}
			{!!info?.heavy_items?.length && (
				<Descriptions size="small" title="Heavy items" bordered>
					{info.heavy_items.map((el: any) => (
						<Item key={el.heavy_item} label={el.heavy_item}>
							${el.heavy_item_price}
						</Item>
					))}
				</Descriptions>
			)}
			<Descriptions size="small" title="Workers" bordered>
				{info?.workers?.map((el) => (
					<Item key={el.worker.id} label={el.worker?.display_name || getUser(Number(el.worker))?.name}>
						${el.salary} / {el.payment_type}
					</Item>
				)
				)}
			</Descriptions>
		</Space>
	) : (
		<Skeleton />
	);
};
