import React, { useCallback, useState } from 'react';
import { Form, Input } from 'antd';
import { Autocomplete, useJsApiLoader, Libraries } from '@react-google-maps/api';

const libraries = ['places'] as Libraries;

interface GoogleAutocompleteProps {
	name: number,
	setAddress: (field: string, name: number, address?: string) => void
	field: string
}

export const GoogleAutocomplete = ({ name, setAddress, field }: GoogleAutocompleteProps) => {
	const [map, setMap] = useState<google.maps.places.Autocomplete | null>(null);
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS || '',
		language: 'en',
		libraries,
		region: 'us',
		id: 'google-map'
	});
	const onLoad = useCallback((map: google.maps.places.Autocomplete) => {
		setMap(map);
	}, []);

	const onUnmount = useCallback(() => {
		setMap(null);
	}, []);

	const onPlaceChanged = () => {
		if (map) {
			setAddress(field, name, map.getPlace()?.formatted_address);
		}
	};
	return isLoaded && (
		<Autocomplete
			onLoad={onLoad}
			onPlaceChanged={() => onPlaceChanged()}
			onUnmount={onUnmount}
		>
			<Form.Item
				name={[name, 'full_address']}
				label={`${field} address`}
				style={{ width: '100%' }}
			>
				<Input placeholder="Search address..." disabled={!isLoaded} />
			</Form.Item>
		</Autocomplete>
	);
};
