import React, { useMemo, useRef } from 'react';
import { Calendar, dayjsLocalizer, SlotInfo, View, Views } from 'react-big-calendar';
import dayjs from 'dayjs';
import { IWork } from 'models';
import { useSwipeable } from 'react-swipeable';
import { Button, Space } from 'antd';
import { useMedia } from 'hooks';

import { WorkTitle } from './WorkTitle';

const localizer = dayjsLocalizer(dayjs);

interface MyCalendarProps {
	data: IWork[],
	setWork: (value: IWork) => void,
	setSlot: (value: SlotInfo) => void,
	onRangeChange: (range: Date[] | { start: Date; end: Date }, view?: View) => void
}

export const MyCalendar = ({ data, setWork, onRangeChange, setSlot }: MyCalendarProps) => {
	const calendarRef = useRef<any | null>(null);
	const { isMobile } = useMedia();
	const worksList = useMemo(() => data.map((el) => {
		const [hours, minutes] = el?.acf?.customer_info?.time.split(':').map(Number);
		return {
			id: el.id,
			title: <WorkTitle work={el} />,
			allDay: false,
			start: dayjs(el.acf.date).add(hours, 'hours').add(minutes, 'minutes').toDate(),
			end: dayjs(el.acf.date).add(hours, 'hours').add((60 + minutes), 'minutes').toDate(),
			work: el
		};
	}), [data]);

	const handlers = useSwipeable({
		onSwipedLeft: () => {
			calendarRef.current?.handleNavigate('NEXT');
		},
		onSwipedRight: () => {
			calendarRef.current?.handleNavigate('PREV');
		},
		delta: 100
	});

	return (
		<div {...handlers}>
			<Calendar
				defaultView={Views.DAY}
				localizer={localizer}
				events={worksList}
				startAccessor="start"
				endAccessor="end"
				showMultiDayTimes
				views={[Views.DAY, Views.MONTH]}
				style={{ height: (isMobile && calendarRef.current?.props.view === Views.MONTH) ? '120vh' : 'calc(100vh - 100px)' }}
				onSelectEvent={(val) => setWork(val.work)}
				onSelectSlot={(val) => setSlot(val)}
				selectable
				onRangeChange={onRangeChange}
				ref={calendarRef}
				min={dayjs().startOf('day').add(5, 'hour').toDate()}
				max={dayjs().startOf('day').add(20, 'hour').toDate()}
				components={{
					toolbar: ({ date, onNavigate, onView, view }) => (
						<Space.Compact block style={{ marginBottom: 20 }}>
							<Button
								type="primary"
								block
								onClick={() => onNavigate('PREV')}
							>
								Back
							</Button>
							<Button
								type="primary"
								block
								onClick={() => onNavigate('NEXT')}
							>
								Next
							</Button>
							<Button block>{dayjs(date).format('ddd MMM D')}</Button>
							<Button
								type="primary"
								block
								ghost={view === 'month'}
								onClick={() => onView('day')}
							>
								Day
							</Button>
							<Button
								type="primary"
								block
								ghost={view === 'day'}
								onClick={() => onView('month')}
							>
								Month
							</Button>
						</Space.Compact>
					)
				}}
			/>
		</div>
	);
};
