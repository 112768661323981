import React from 'react';
import { Descriptions, Tag } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';
import { IWork } from 'models';
import { formatPhoneNumber } from 'utils';

const { Item } = Descriptions;

const TripleSign = () => (
	<>
		<ExclamationOutlined style={{ color: 'red' }} />
		<ExclamationOutlined style={{ color: 'red' }} />
	</>
);

interface WorkInfoForForemenProps {
	work: IWork
}

export const WorkInfoForForemen = ({ work }: WorkInfoForForemenProps) => {
	const {
		acf: { date, customer_info, foreman_info }
	} = work;
	const payment =
		customer_info.payment === 'cash'
			? `$${customer_info.result} (cash) / $${+customer_info.result + 10} (card)`
			: `$${Number(customer_info.result) - 10} (cash) / $${+customer_info.result} (card)`;

	return (
		<Descriptions size="small" title={`Work ${work.id}`} bordered>
			<Item label="Date">{date}</Item>
			<Item label="Time">{customer_info.time}</Item>
			<Item label="Movers">{customer_info.movers}</Item>
			<Item label="Rate">{payment}</Item>
			<Item label="Arriving fee">{customer_info.truck_fee}</Item>
			<Item label="Move size">{customer_info.bedroom}</Item>
			{!!foreman_info.notes && (
				<Item label="Notes">
					<TripleSign />
					{foreman_info.notes}
					<TripleSign />
				</Item>
			)}
			<Item label="Pick up">
				<a
					rel="noreferrer"
					target="_blank"
					href={`https://maps.google.com/?q=${customer_info.pickup_address[0].full_address}`}
				>
					{customer_info.pickup_address[0].full_address}
					{customer_info.pickup_address[0].unit !== '' && `, ${customer_info.pickup_address[0].unit}`},{' '}
					{customer_info.pickup_address[0].zip}
				</a>
			</Item>
			<Item label="Drop off">
				<a
					rel="noreferrer"
					target="_blank"
					href={`https://maps.google.com/?q=${customer_info.dropoff_address[0].full_address}`}
				>
					{customer_info.dropoff_address[0].full_address}
					{customer_info.dropoff_address[0].unit !== '' && `, ${customer_info.dropoff_address[0].unit}`},{' '}
					{customer_info.dropoff_address[0].zip}
				</a>
			</Item>
			{customer_info.packing !== 'No Packing' && <Item label="Packing">{customer_info.packing}</Item>}
			{customer_info.supplies === 'yes' && (
				<Item label="Supplies">
					{customer_info.small_boxes && `Small boxes: ${customer_info.small_boxes}. `}
					{customer_info.medium_boxes && `Medium boxes: ${customer_info.medium_boxes}. `}
					{customer_info.wrapping_paper && `Wrapping paper: ${customer_info.wrapping_paper}. `}
				</Item>
			)}
			<Item label="Customer">{customer_info.customer_name}</Item>
			<Item label="Phone">
				<a href={`tel:${customer_info.customer_phone}`}>{formatPhoneNumber(customer_info.customer_phone)}</a>
			</Item>
			{customer_info.heavyItems !== 'No' && <Item label="Heavy items">{customer_info.heavyItems}</Item>}
			{!!foreman_info.workers?.length && (
				<Item label="Helpers">
					{foreman_info.workers.map(
						(el, idx) =>
							el.worker_role === 'helper' && (
								<Tag color="blue" key={idx}>
									{el.worker.display_name}
								</Tag>
							)
					)}
				</Item>
			)}
			{foreman_info.truck.length > 0 && (
				<Item label="Trucks">
					{foreman_info.truck.map((el, idx) => (
						<Tag color="purple" key={idx}>
							{el.label}
						</Tag>
					))}
				</Item>
			)}

			{customer_info.truck === 'No truck just a labor' && (
				<Item label="Truck">
					<Tag color="red">No Truck</Tag>
				</Item>
			)}

			{customer_info.note && (
				<Item label="Customer Notes">
					<TripleSign />
					{customer_info.note}
					<TripleSign />
				</Item>
			)}
		</Descriptions>
	);
};

