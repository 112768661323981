import React, { useState } from 'react';
import { Table, TableProps, Tag } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { workService } from 'services';
import { IAssignedWorker, IKeys, IWork, IWorkState } from 'models';
import { COMPLETED_ROUTE } from 'routes/consts';
import { useUser } from 'hooks';

export const AllCompletedWorks = () => {
	const { getUser, isUsersLoading } = useUser();
	const [page, setPage] = useState(1);
	const { isLoading, data } = useQuery({
		queryFn: () => workService.getAll({
			state: IWorkState.COMPLETED,
			sortbydate: true,
			page
		}),
		queryKey: [IKeys.WORKS, { state: IWorkState.COMPLETED, page }]
	});

	const columns: TableProps<IWork>['columns'] = [
		{
			title: 'Date',
			dataIndex: ['acf', 'date'],
			key: 'date',
			render: date => dayjs(date).format('MM/DD')
		},
		{
			title: 'Work №',
			dataIndex: ['id'],
			key: 'id',
			render: id => <Link to={`${COMPLETED_ROUTE}/${id}`}>{id}</Link>
		},
		{
			title: 'Workers count',
			dataIndex: ['acf', 'foreman_info', 'workers_count'],
			key: 'workers_count'
		},
		{
			title: 'Workers',
			dataIndex: ['acf', 'foreman_info', 'workers'],
			key: 'workers',
			render: (workers: IAssignedWorker[]) => workers.map((el) => <Tag key={el?.worker.id || Number(el.worker)} color={el.worker_role === 'foreman' ? 'success' : 'processing'}>{el?.worker.display_name || getUser(Number(el.worker))?.name}</Tag>)
		},
		{
			title: 'Payment',
			dataIndex: ['acf', 'foreman_info', 'payment'],
			key: 'payment'
		},
		{
			title: 'Total',
			dataIndex: ['acf', 'foreman_info', 'total'],
			key: 'total',
			render: text => `$${text}`
		},
		{
			title: 'Time',
			dataIndex: ['acf', 'foreman_info', 'total_time'],
			key: 'total_time',
			render: text => `${Math.round(text)}h`
		}
	];
	return (
		<Table<IWork>
			loading={isLoading || isUsersLoading}
			columns={columns}
			dataSource={data?.data}
			rowKey={record => record.id}
			pagination={{
				current: page,
				pageSize: 10,
				total: data?.headers['x-wp-total'],
				onChange: (page) => setPage(page),
				showSizeChanger: false
			}}
			summary={pageData => {
				let totalIncome = 0;
				pageData.forEach(({ acf: { foreman_info: { total } } }) => {
					totalIncome += Number(total);
				});

				return (
					<>
						<Table.Summary.Row>
							<Table.Summary.Cell index={0} />
							<Table.Summary.Cell index={1} />
							<Table.Summary.Cell index={2} />
							<Table.Summary.Cell index={3} />
							<Table.Summary.Cell index={4} />
							<Table.Summary.Cell index={5}>{`$${totalIncome}`}</Table.Summary.Cell>
							<Table.Summary.Cell index={6} />
						</Table.Summary.Row>
					</>
				);
			}}
		/>
	);
};
