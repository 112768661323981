import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Divider, Form, Input, InputNumber, message, Row, Select, Skeleton, Switch, Typography } from 'antd';
import { CheckOutlined, CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from 'hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { workService } from 'services';
import { IAssignedWorker, IKeys, IUserRoles, IWork, IWorkState, IWorkStatus } from 'models';
import { MY_WORKS_ROUTE } from 'routes/consts';
import { WorkInfoForForemen } from 'components/UI';
import { paymentS, timeRange } from 'utils';

import { calculator } from '../utils/calculator';

const { Item, List } = Form;
const { Option } = Select;

interface ExtendAssignedWorker extends IAssignedWorker {
	g_role?: IUserRoles
}

export const CompleteWorkForm = () => {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { helpersForemans, getUser, isUsersLoading } = useUser();
	const { data, isLoading, isSuccess } = useQuery({
		queryFn: () => workService.getOne(params?.id || 0),
		queryKey: [IKeys.WORKS, { id: params?.id }]
	});
	const { isPending, mutate } = useMutation({
		mutationFn: (data: Partial<IWork>) => workService.update(data, Number(params?.id)),
		onSuccess: () => {
			message.success('Job updated!');
			navigate(MY_WORKS_ROUTE);
		}
	});
	const [form] = Form.useForm<Partial<IWork>>();
	const [heavyItems, setHeavyItems] = useState(false);
	const [addItems, setAddItems] = useState(false);
	const [tips, setTips] = useState(false);
	const [truckFee, setTruckFee] = useState(true);
	const workersOption = helpersForemans.map((el) => ({ value: el.id, label: el.name }));

	const calculateTotal = useCallback((allValues: Partial<IWork>) => {
		(data?.acf.date && allValues?.acf) && calculator(allValues.acf.foreman_info, data?.acf.date, form);
	}, [data?.acf.date, form]);
	const onFinish = (values: Partial<IWork>) => {
		if (values.acf?.foreman_info) {
			let data = values.acf.foreman_info;
			data = {
				...data,
				workers_count: Number(data?.workers?.length),
				workers: data?.workers?.map((el: ExtendAssignedWorker) => ({
					...el,
					salary: Math.round(Number(data?.total_time) * (el.g_role === 'foreman' ? 25 : 20)),
					status: data.payment === 'cash' && el.payment_type === 'cash'
				})) || []
			};
			values.acf.foreman_info = data;
			mutate(values);
		}
	};

	const confirmJob = () => {
		const values = {
			acf: { foreman_info: { status: IWorkStatus.CONFIRMED } }
		} as Partial<IWork>;
		mutate(values);
	};

	const onChangeHeavy = () => {
		setHeavyItems(!heavyItems);
		form.setFieldsValue({
			acf: { foreman_info: { heavy_items: [] } }
		});
	};
	const onChangeMaterial = () => {
		setAddItems(!addItems);
		form.setFieldsValue({
			acf: { foreman_info: { add_items: [] } }
		});
	};
	const onChangeTips = () => {
		setTips(!tips);
		form.setFieldsValue({
			acf: { foreman_info: { tips: 0 } }
		});
	};
	const onTruckFee = () => {
		setTruckFee(!truckFee);
		form.setFieldsValue({
			acf: {
				foreman_info: {
					driving_time_price: '',
					driving_time_fee: truckFee,
					truck_fee: !truckFee
				}
			}
		});
	};

	const workersArray = useMemo(() => data?.acf.foreman_info?.workers
		?.map((el) => ({
			worker: el.worker?.id || el.worker,
			worker_role: el.worker_role,
			g_role: getUser(el.worker.id || Number(el.worker))?.roles[0]
		}))
		.sort((a: any) => (a.worker_role === IUserRoles.FOREMAN ? -1 : 1)), [data?.acf.foreman_info?.workers, getUser]);

	return ((!isLoading || !isUsersLoading) && isSuccess) ? (
		<>
			<WorkInfoForForemen work={data} />
			<Button
				block
				danger={data?.acf.foreman_info.status === IWorkStatus.PENDING}
				onClick={confirmJob}
				disabled={data?.acf.foreman_info.status === IWorkStatus.CONFIRMED}
				type="primary"
			>
				{data?.acf.foreman_info.status === IWorkStatus.PENDING ? 'Please confirm job!' : 'Job confirmed!'}
			</Button>
			<Divider />
			<Form
				name="addWork"
				onFinish={onFinish}
				autoComplete="off"
				layout="vertical"
				initialValues={{
					acf: {
						state: IWorkState.COMPLETED,
						foreman_info: {
							total_time: 0,
							truck_fee: true,
							discount: 'no',
							workers: workersArray
						}
					}
				}}
				form={form}
				onValuesChange={(_, values) => calculateTotal(values)}
				size="large"
			>
				<Row gutter={16}>
					<Col span={24}>
						<Item
							label="Payment method"
							name={['acf', 'foreman_info', 'payment']}
							rules={[{ required: true, message: 'Please select payment time!' }]}
						>
							<Select placeholder="Payment method" options={paymentS} />
						</Item>
					</Col>
					<Col span={24}>
						<Typography.Title level={4}>Workers</Typography.Title>
						<List name={['acf', 'foreman_info', 'workers']}>
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, ...restField }) => (
										<Row gutter={8} justify="space-between" key={key}>
											<Col span={7}>
												<Item {...restField} name={[name, 'worker']}>
													<Select options={workersOption} />
												</Item>
											</Col>
											<Col span={7}>
												<Item
													{...restField}
													name={[name, 'payment_type']}
													rules={[{ required: true, message: 'Choose payment type' }]}
												>
													<Select placeholder="Payment">
														<Option value="check">Check</Option>
														<Option value="cash">Cash</Option>
													</Select>
												</Item>
											</Col>
											<Col span={7}>
												<Item
													{...restField}
													name={[name, 'worker_role']}
													rules={[{ required: true, message: 'Choose worker role' }]}
												>
													<Select placeholder="Role">
														<Option value="foreman">foreman</Option>
														<Option value="helper">helper</Option>
													</Select>
												</Item>
											</Col>
											<Col span={3}>
												<MinusCircleOutlined style={{ color: 'red' }} onClick={() => remove(name)} />
											</Col>
											<Item {...restField} name={[name, 'g_role']} hidden>
												<Input />
											</Item>
											<Item {...restField} name={[name, 'salary']} hidden>
												<Input />
											</Item>
										</Row>
									))}
									<Button
										style={{ marginBottom: '20px' }}
										type="primary"
										onClick={() => add()}
										block
										icon={<PlusOutlined />}
									>
										Add worker
									</Button>
								</>
							)}
						</List>
					</Col>
					<Col span={24}>
						<Typography.Title level={4}>Work</Typography.Title>
					</Col>
					<Col span={12}>
						<Item
							name={['acf', 'foreman_info', 'loading_time']}
							label="Loading time"
							rules={[{ required: true, message: 'Please input loading time!' }]}
						>
							<Select
								placeholder="00:00"
								options={timeRange}
								showSearch
								filterOption={(input: string, option?: { label: string; value: string }) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							/>
						</Item>
					</Col>
					<Col span={12}>
						<Item name={['acf', 'foreman_info', 'loading_movers']} label="Additional movers">
							<InputNumber placeholder="0" />
						</Item>
					</Col>
					<Col span={8}>
						<Item
							name={['acf', 'foreman_info', 'drive_time']}
							label="Drive time"
							rules={[{ required: true, message: 'Please input driving time!' }]}
						>
							<Select
								placeholder="00:00"
								options={timeRange}
								showSearch
								filterOption={(input: string, option?: { label: string; value: string }) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							/>
						</Item>
					</Col>
					<Col span={12}>
						<Item name={['acf', 'foreman_info', 'double_drive']} label="Double drive?" valuePropName="checked">
							<Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
						</Item>
					</Col>
					<Col span={12}>
						<Item
							name={['acf', 'foreman_info', 'unloading_time']}
							label="Unloading time"
							rules={[{ required: true, message: 'Please input unloading time!' }]}
						>
							<Select
								placeholder="00:00"
								options={timeRange}
								showSearch
								filterOption={(input: string, option?: { label: string; value: string }) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							/>
						</Item>
					</Col>
					<Col span={12}>
						<Item name={['acf', 'foreman_info', 'unloading_movers']} label="Additional movers">
							<InputNumber placeholder="0" />
						</Item>
					</Col>
					<Col xs={12} lg={4}>
						<Item label="Arriving fee" name={['acf', 'foreman_info', 'truck_fee']} valuePropName="checked">
							<Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onTruckFee} />
						</Item>
					</Col>
					<Col xs={12} lg={6}>
						<Item
							label="Driving time fee"
							name={['acf', 'foreman_info', 'driving_time_fee']}
							valuePropName="checked"
						>
							<Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onTruckFee} />
						</Item>
					</Col>
					<Col xs={12} lg={6}>
						{!truckFee && (
							<Item label="Driving time price" name={['acf', 'foreman_info', 'driving_time_price']}>
								<InputNumber
									formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									placeholder="Driving time price"
								/>
							</Item>
						)}
					</Col>
					<Col span={24}>
						<Typography.Title level={4}>
							Heavy items <Switch onChange={onChangeHeavy} checkedChildren="Yes" unCheckedChildren="No" />
						</Typography.Title>
						{heavyItems && (
							<List name={['acf', 'foreman_info', 'heavy_items']}>
								{(fields, { add, remove }) => (
									<>
										{fields.map(({ key, name, ...restField }) => (
											<Row key={key} gutter={16}>
												<Col span={11}>
													<Item {...restField} name={[name, 'heavy_item']}>
														<Select placeholder="Heavy item">
															<Option value="piano">Piano</Option>
															<Option value="safe">Safe</Option>
															<Option value="heavy_furniture">Heavy furniture</Option>
														</Select>
													</Item>
												</Col>
												<Col span={11}>
													<Item
														{...restField}
														name={[name, 'heavy_item_price']}
													>
														<InputNumber
															placeholder="Heavy item price"
															formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
														/>
													</Item>
												</Col>
												<Col span={2}>
													<MinusCircleOutlined style={{ color: 'red' }} onClick={() => remove(name)} />
												</Col>
											</Row>
										))}
										<Form.Item>
											<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
												Add item
											</Button>
										</Form.Item>
									</>
								)}
							</List>
						)}
					</Col>
					<Col span={24}>
						<Typography.Title level={4}>
							Packing materials <Switch onChange={onChangeMaterial} checkedChildren="Yes" unCheckedChildren="No" />
						</Typography.Title>
						{addItems && (
							<List name={['acf', 'foreman_info', 'add_items']}>
								{(fields, { add, remove }) => (
									<>
										{fields.map(({ key, name, ...restField }) => (
											<Row key={key} gutter={16}>
												<Col span={11}>
													<Item {...restField} name={[name, 'add_item']}>
														<Select placeholder="Additional item">
															<Option value="small">Small box</Option>
															<Option value="medium">Medium box</Option>
															<Option value="wardrobe">Wardrobe</Option>
															<Option value="wrap_paper">Wrap paper</Option>
															<Option value="bubble_wrap">Bubble wrap</Option>
															<Option value="blankets">Blankets</Option>
														</Select>
													</Item>
												</Col>
												<Col span={11}>
													<Item {...restField} name={[name, 'add_item_count']}>
														<InputNumber placeholder="Count" />
													</Item>
												</Col>
												<Col span={2}>
													<MinusCircleOutlined style={{ color: 'red' }} onClick={() => remove(name)} />
												</Col>
											</Row>
										))}
										<Form.Item>
											<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
												Add item
											</Button>
										</Form.Item>
									</>
								)}
							</List>
						)}
					</Col>
					<Col span={24}>
						<Typography.Title level={4}>
							Tips <Switch onChange={onChangeTips} checkedChildren="Yes" unCheckedChildren="No" />
						</Typography.Title>
					</Col>
					<Col span={24}>
						{tips && (
							<Item name={['acf', 'foreman_info', 'tips']}>
								<InputNumber
									formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								/>
							</Item>
						)}
					</Col>
					<Col xs={24} span={8}>
						<Item name={['acf', 'foreman_info', 'total']}>
							<Input
								prefix="Total: $"
								disabled
								style={{ color: '#fff' }}
							/>
						</Item>
					</Col>
					<Col span={24}>
						<Item name={['acf', 'foreman_info', 'total_time']} hidden>
							<InputNumber />
						</Item>
						<Item name={['acf', 'state']} hidden>
							<Input />
						</Item>
						<Item>
							<Button loading={isPending} type="primary" htmlType="submit">
								Submit
							</Button>
						</Item>
					</Col>
				</Row>
			</Form>
		</>
	) : (
		<Skeleton />
	);
};
