import React from 'react';
import { Button, DatePicker } from 'antd';

interface DataFilterProps {
	setSelectedKeys: any,
	confirm: any,
	clearFilters: any
}
const DataFilter = ({ setSelectedKeys, confirm, clearFilters }: DataFilterProps) => {
	return (
		<>
			<DatePicker
				onChange={(date, dateString) => setSelectedKeys(dateString ? [dateString] : [])}
				picker="month"
				style={{ width: '200px', display: 'block' }}
			/>
			<Button type="primary" onClick={() => confirm()}>Filter</Button>
			<Button onClick={() => clearFilters()}>Reset</Button>
		</>
	);
};

export default DataFilter;
