import { useMemo } from 'react';
import { CalendarOutlined, CodeSandboxOutlined, CreditCardOutlined, FileDoneOutlined, HomeOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { userStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { NavLink, useNavigate } from 'react-router-dom';
import { ALL_WORKS_ROUTE, LOGIN, MY_WORKS_ROUTE, SALARIES_ROUTE, SCHEDULE_ROUTE } from 'routes/consts';
import { useMedia } from 'hooks';
import { IUserRoles } from 'models';

export const AppMenu = observer(() => {
	const { isMobile } = useMedia();
	const navigate = useNavigate();
	const items = useMemo(() => {
		const menuItems = [
			{
				label: <NavLink to={SALARIES_ROUTE}><CreditCardOutlined style={{ fontSize: 20 }} /> Salaries</NavLink>,
				key: 'salaries'
			},
			{
				label: <NavLink to={MY_WORKS_ROUTE}><CodeSandboxOutlined style={{ fontSize: 20 }} /> My works</NavLink>,
				key: 'my-works'
			},
			{
				label: <span onClick={() => { userStore.logout(); navigate('/'); }}><LogoutOutlined style={{ fontSize: 20 }} /> Log out</span>,
				key: 'logout'
			}
		];
		if (IUserRoles.ADMIN === userStore.data?.role[0] || IUserRoles.MANAGER === userStore.data?.role[0]) {
			menuItems.unshift({
				label: <NavLink to={LOGIN}><HomeOutlined style={{ fontSize: 20 }} /> Works</NavLink>,
				key: 'home'
			}, {
				label: <NavLink to={ALL_WORKS_ROUTE}><FileDoneOutlined style={{ fontSize: 20 }} /> Completed</NavLink>,
				key: 'completed'
			}, {
				label: <NavLink to={SCHEDULE_ROUTE}><CalendarOutlined style={{ fontSize: 20 }} /> Schedule</NavLink>,
				key: 'schedule'
			});
		}
		return menuItems;
	}, [navigate]);

	return isMobile ? (
		<div className="mobile-menu">
			{items.map(el => (
				<div className="mobile-menu-item" key={el?.key}>
					{el.label}
				</div>
			))}
		</div>
	) : (
		<Menu
			theme="dark"
			mode="horizontal"
			items={items}
			style={{ minWidth: 0, flex: 'auto' }}
		/>
	);
});