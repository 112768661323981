import { IRCompletedWork, IWork } from 'models';

import { $api } from '../http';

const _fields = 'acf,id,author,date';
const link = '/wp/v2/works';
class WorkService {
	async getAll(params?: any) {
		try {
			const res = await $api.get<IWork[]>(link, { params: { ...params, _fields } });
			return res;
		} catch (error) {
			throw error;
		}
	}

	async getOne(id: number | string, params?: any) {
		try {
			const res = await $api.get<IWork>(`${link}/${id}`, { params: { ...params, _fields } });
			return res.data;
		} catch (error) {
			throw error;
		}
	}

	async getByUser(id: number, params?: any) {
		try {
			const res = await $api.get<IRCompletedWork>(`/myplug/v2/users/${id}`, { params });
			return res;
		} catch (error) {
			throw error;
		}
	}

	async update(data: Partial<IWork>, id?: number) {
		try {
			const res = await $api.post<IWork>(`${link}/${id}`, data);
			return res.data;
		} catch (error) {
			throw error;
		}
	}

	async create(data: Partial<IWork>) {
		try {
			const res = await $api.post<IWork>(link, data);
			return res.data;
		} catch (error) {
			throw error;
		}
	}

	async delete(id?: number) {
		try {
			const res = await $api.delete<IWork>(`${link}/${id}`);
			return res.data;
		} catch (error) {
			throw error;
		}
	}
};

export const workService = new WorkService();