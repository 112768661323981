export interface IUserResponse {
	token: string;
	user: IUser;
}

export interface IUser {
	id: number
	user_display_name: string
	user_email: string
	user_nicename: string
	role: IUserRoles[]
}

export interface IWorker {
	acf: {
		phone: string,
		works: number[]
	},
	id: number,
	name: string,
	roles: IUserRoles[]
	slug: string,
	display_name: string
}

export enum IUserRoles {
	ADMIN = 'administrator',
	HELPER = 'helper',
	FOREMAN = 'foreman',
	MANAGER = 'manager'
}