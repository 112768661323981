import React from 'react';
import { Switch, Table, TableProps } from 'antd';
import { ICompletedWork, IRCompletedWork, IWorkStatus } from 'models';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { userStore } from 'stores';
import { useMedia } from 'hooks';

import DataFilter from './DataFilter';

interface MyCompletedWorksProps {
	data?: AxiosResponse<IRCompletedWork>,
	updateWork: (work: ICompletedWork, status: IWorkStatus) => void,
	isFetching: boolean
}

export const MyCompletedWorks = ({ data, updateWork, isFetching }: MyCompletedWorksProps) => {
	const { isMobile } = useMedia();
	const columns: TableProps<ICompletedWork>['columns'] = [
		{
			title: '№',
			dataIndex: 'id',
			key: 'id'
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: el => dayjs(el).format('MM/DD'),
			filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
				<DataFilter setSelectedKeys={setSelectedKeys} confirm={confirm} clearFilters={clearFilters} />
			),
			onFilter: (value, record) => dayjs(record.date).format('YYYY-MM') === value
		},
		{
			title: isMobile ? 'H' : 'Hours',
			dataIndex: 'total_time',
			key: 'total_time'
		},
		{
			title: isMobile ? 'R' : 'Role',
			dataIndex: ['worker', 'worker_role'],
			key: 'worker',
			render: worker_role => worker_role[0]
		},
		{
			title: '$',
			dataIndex: ['worker', 'salary'],
			key: 'total',
			render: (salary, record) => {
				const tips = record.tips ? Number(record.tips) / Number(record.workers_count) : 0;
				return `$${+salary + tips}`;
			}
		},
		{
			title: 'Cash/Check',
			dataIndex: ['worker', 'payment_type'],
			key: 'payment_type'
		},
		{
			title: 'Status',
			dataIndex: ['worker', 'status'],
			key: 'status',
			render: (status, work) => {
				return <Switch
					checkedChildren="Paid"
					unCheckedChildren="Unpaid"
					defaultChecked={status}
					onChange={() => updateWork(work, status)}
					disabled={!userStore.isAdmin}
				/>;
			}
		}

	];
	return (
		<Table<ICompletedWork>
			loading={isFetching}
			dataSource={data?.data.works}
			columns={columns}
			rowKey={el => el.id}
			summary={pageData => {
				const { totalHours, totalSalary } = pageData.reduce((res, { worker, total_time, tips, workers_count }) => ({
					totalHours: res.totalHours + +total_time,
					totalSalary: res.totalSalary + +worker?.salary + (+tips / +workers_count)
				}), { totalHours: 0, totalSalary: 0 });

				return (
					<>
						<Table.Summary.Row>
							<Table.Summary.Cell index={0} />
							<Table.Summary.Cell index={1}>{`${Math.round((totalHours) * 100) / 100}h`}</Table.Summary.Cell>
							<Table.Summary.Cell index={2} />
							<Table.Summary.Cell index={3}>{`$${totalSalary}`}</Table.Summary.Cell>
							<Table.Summary.Cell index={4} />
							<Table.Summary.Cell index={5} />
						</Table.Summary.Row>
					</>
				);
			}}
		/>

	);
};
