import dayjs from 'dayjs';
import { IWork } from 'models';

export const copyOrderInfo = ({ acf, id }: IWork) => {
	const values = acf.customer_info;
	return `Request: ${id}
Status: ${acf.state}
Move Date: ${dayjs(acf.date).format('MM/DD')}
Start Time: ${dayjs(values.time, 'h:m').format('h a')}
Crew Size: ${values.movers}
Hourly Rate: $${values.result}/h
Payment: ${values.payment}
Arriving fee: ${values.truck_fee}
Type of Service: Moving
Size of Move: ${values.bedroom}
Truck: ${values.truck}

Moving from: ${values.pickup_address?.[0]?.full_address}, ${values.pickup_address?.[0]?.zip}
${values.pickup_address?.[0]?.unit === '' ? '' : `Unit: ${values.pickup_address?.[0]?.unit}`}

Moving to: ${values.dropoff_address?.[0]?.full_address}, ${values.dropoff_address?.[0]?.zip}
${values.dropoff_address?.[0]?.unit === '' ? '' : `Unit: ${values.dropoff_address?.[0]?.unit}`}

Packing: ${values.packing}

Customer: ${values.customer_name}
Phone: ${values.customer_phone}
Email: ${values.customer_email}
Contact person: ${values.contact_name}
Phone: ${values.contact_phone}
Email: ${values.contact_email}

From: ${values.howfrom}

${values.note && `Customer Notes: ${values.note}`}

${acf.foreman_info.notes && `Notes for foreman: ${acf.foreman_info.notes}`}
`;
};
