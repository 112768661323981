import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { IWork } from 'models';
import { workService } from 'services';

interface UseWorkMutationProps {
	queryKey: any[]
	onSettled?: () => void,
	work?: IWork | null,
	onSettledDelete?: () => void
}

export const useWorkMutation = ({ queryKey, onSettled, work, onSettledDelete }: UseWorkMutationProps) => {
	const [messageApi, contextHolder] = message.useMessage();
	const client = useQueryClient();
	const updateWork = useMutation({
		mutationFn: (data: Partial<IWork>) => {
			if (work) {
				return workService.update(data, work.id);
			} else {
				return workService.create(data);
			}
		},
		onSuccess: (data) => {
			if (work) {
				client.setQueryData(queryKey, (works: AxiosResponse<IWork[]> | undefined) => {
					const updatedData = {
						...works,
						data: works?.data.map(el => el.id === data.id ? data : el)
					};
					return (works && data) ? updatedData : works;
				});
			} else {
				client.setQueryData(queryKey, (works: AxiosResponse<IWork[]> | undefined) => {
					const updatedData = {
						...works,
						data: [data, ...works?.data || []]
					};
					return (works && data) ? updatedData : works;
				});
			}
			messageApi.success('Done!');
		},
		onSettled
	});

	const deleteWork = useMutation({
		mutationFn: () => workService.delete(work?.id),
		onSuccess: (data) => {
			client.setQueryData(queryKey, (works: AxiosResponse<IWork[]> | undefined) => {
				const updatedData = {
					...works,
					data: works?.data.filter(el => el.id !== data.id)
				};
				return (works && data) ? updatedData : works;
			});
			messageApi.success(`Work #${data.id} was deleted`);
		},
		onSettled: () => {
			onSettledDelete && onSettledDelete();
		}
	});
	return {
		contextHolder,
		updateWork,
		deleteWork,
		messageApi
	};
};