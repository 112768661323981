import React, { useEffect, useMemo } from 'react';
import { Button, DatePicker, Divider, Flex, Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { IUserRoles, IWork, IWorkState } from 'models';
import dayjs from 'dayjs';
import { userStore } from 'stores';
import { GoogleAutocomplete, SelectForemanHelpers } from 'components/UI';
import {
	bedroomS,
	heavyItemsS,
	moversS,
	packingS,
	paymentS,
	referralS,
	truckSizeS,
	trucksS,
	typeOfResS
} from 'utils';
import { SlotInfo } from 'react-big-calendar';

const { Item } = Form;
const { Option } = Select;

interface CreateJobFormProps {
	onFinish: (values: IWork) => void
	work: IWork | null,
	slot?: SlotInfo | null,
	queryKey: any[],
	form: FormInstance<IWork>
}

export const CreateWorkForm = ({ onFinish, work, slot, form }: CreateJobFormProps) => {
	const onMoversCountChange = (value: any) => {
		const count = parseInt(value);
		form.setFieldsValue({
			acf: { customer_info: { result: (count * 40 + 40).toString() } }
		});
	};

	const values = useMemo(() => {
		if (slot) {
			return {
				acf: {
					date: dayjs(slot.start),
					state: IWorkState.CONFIRMED,
					customer_info: {
						truck_fee: '$50',
						time: dayjs(slot.start).format('HH:mm')
					}
				},
				author: userStore.data?.id
			};
		}

		if (work) {
			const workersArr = work?.acf?.foreman_info?.workers;
			const foreman = workersArr?.filter(el => el.worker_role === IUserRoles.FOREMAN).map((el) => el.worker?.id || el.worker) || [];
			const helpers = workersArr?.filter(el => el.worker_role === IUserRoles.HELPER).map((el) => el.worker?.id || el.worker) || [];
			work.acf.customer_info.small_boxes = Number(work.acf.customer_info.small_boxes);
			work.acf.customer_info.medium_boxes = Number(work.acf.customer_info.medium_boxes);
			work.acf.customer_info.wrapping_paper = Number(work.acf.customer_info.wrapping_paper);
			if (workersArr) {
				work.acf.foreman_info.workers = workersArr.map(el => ({ ...el, salary: Number(el.salary) }));
			}
			return {
				acf: {
					...work.acf,
					state: IWorkState.CONFIRMED,
					date: dayjs(work.acf.date)
				},
				id: work.id,
				foreman,
				helpers
			};
		}
	}, [slot, work]);

	const setAddress = (field: string, name: number, address?: string) => {
		const data = form.getFieldsValue();
		const { pickup_address, dropoff_address } = data.acf.customer_info;
		if (field === 'pickup') {
			const arr = pickup_address.map((el, index) => (index === name ? { ...el, full_address: address } : el));
			form.setFieldsValue({ acf: { customer_info: { pickup_address: arr } } });
		} else if (field === 'dropoff') {
			const arr = dropoff_address.map((el, index) => (index === name ? { ...el, full_address: address } : el));
			form.setFieldsValue({ acf: { customer_info: { dropoff_address: arr } } });
		}
	};

	useEffect(() => {
		return form.resetFields();
	}, [form]);

	return (
		<Form
			form={form}
			name="addOrder"
			autoComplete="off"
			layout="horizontal"
			initialValues={{
				...values,
				status: 'publish'
			}}
			onFinish={onFinish}
			key={work?.id}
		>
			{userStore.isAdmin && (
				<>
					<SelectForemanHelpers form={form} work={work} />
					<Item name={['acf', 'foreman_info', 'truck']} label="Trucks">
						<Select mode="multiple" options={trucksS} />
					</Item>
				</>
			)}
			{work && (
				<Item name="id">
					<Input prefix="Order:" disabled />
				</Item>
			)}
			<Item name={['acf', 'date']} label="Date">
				<DatePicker format="MM/DD/YYYY" />
			</Item>
			<Item name={['acf', 'customer_info', 'time']} label="Start time">
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'movers']} label="Crew size">
				<Select onChange={onMoversCountChange} options={moversS} />
			</Item>
			<Item name={['acf', 'customer_info', 'result']} label="Hourly Rate">
				<Input prefix="$" />
			</Item>
			<Item name={['acf', 'customer_info', 'payment']} label="Payment">
				<Select options={paymentS} />
			</Item>
			<Item name={['acf', 'customer_info', 'truck_fee']} label="Arriving fee">
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'bedroom']} label="Moving size">
				<Select options={bedroomS} />
			</Item>
			<Item name={['acf', 'customer_info', 'typeofresidency']} label="Type of residency">
				<Select options={typeOfResS} />
			</Item>
			<Item name={['acf', 'customer_info', 'truck']} label="Truck size">
				<Select options={truckSizeS} />
			</Item>
			<Item name={['acf', 'customer_info', 'heavyItems']} label="Have Items more then 300lb">
				<Select options={heavyItemsS} />
			</Item>
			<Divider />
			<Form.List name={['acf', 'customer_info', 'pickup_address']}>
				{(fields, { add, remove }) => (
					<>
						{!fields.length && <Button
							type="primary"
							onClick={() => add()}
						>
							Add pickup address
						</Button>
						}
						{fields.map((field) => (
							<div key={field.name}>
								<GoogleAutocomplete
									name={field.name}
									setAddress={setAddress}
									field="pickup"
								/>
								<Flex justify="space-between">
									<Item
										key={field.name}
										name={[field.name, 'unit']}
										label="Unit"
									>
										<Input placeholder="Unit" />
									</Item>
									<Item
										key={field.name}
										name={[field.name, 'zip']}
										label="Zip"
									>
										<Input placeholder="Zip" />
									</Item>
									<Button
										style={{ width: 60 }}
										type="primary"
										onClick={() => remove(field.name)}
										icon={<MinusCircleOutlined />}
										danger />
									<Button
										style={{ width: 60 }}
										type="primary"
										onClick={() => add()}
										icon={<PlusOutlined />} />
								</Flex>
							</div>
						))}
					</>
				)}
			</Form.List>
			<Divider />
			<Form.List name={['acf', 'customer_info', 'dropoff_address']}>
				{(fields, { add, remove }) => (
					<>
						{!fields.length && <Button
							type="primary"
							onClick={() => add()}
						>
							Add dropoff address
						</Button>
						}
						{fields.map(({ key, name, ...restField }) => (
							<>
								<GoogleAutocomplete
									name={name}
									setAddress={setAddress}
									field="dropoff"
								/>
								<Flex justify="space-between">
									<Item
										{...restField}
										name={[name, 'unit']}
										label="Unit"
									>
										<Input placeholder="Unit" />
									</Item>
									<Item
										{...restField}
										name={[name, 'zip']}
										label="Zip"
									>
										<Input placeholder="Zip" />
									</Item>
									<Button
										style={{ width: 60 }}
										type="primary"
										onClick={() => remove(name)}
										icon={<MinusCircleOutlined />}
										danger
									/>
									<Button
										style={{ width: 60 }}
										type="primary"
										onClick={() => add()}
										icon={<PlusOutlined />}
									/>
								</Flex>
							</>
						))}
					</>
				)}
			</Form.List>
			<Divider />
			<Item name={['acf', 'customer_info', 'packing']} label="Packing">
				<Select options={packingS} />
			</Item>
			<Item name={['acf', 'customer_info', 'supplies']} label="Supplies">
				<Select>
					<Option value="yes">Yes</Option>
					<Option value="no">No</Option>
				</Select>
			</Item>
			<Item name={['acf', 'customer_info', 'small_boxes']} label="Small Box">
				<InputNumber />
			</Item>
			<Item name={['acf', 'customer_info', 'medium_boxes']} label="Medium boxes">
				<InputNumber />
			</Item>
			<Item name={['acf', 'customer_info', 'wrapping_paper']} label="Wrapping paper">
				<InputNumber />
			</Item>
			<Item
				name={['acf', 'customer_info', 'customer_name']}
				label="Customer name"
				rules={[{ required: true }]}
			>
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'customer_phone']} label="Customer phone" rules={[{ required: true }]}>
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'customer_email']} label="Customer email">
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'contact_name']} label="Contact name">
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'contact_phone']} label="Contact phone">
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'contact_email']} label="Contact email">
				<Input />
			</Item>
			<Item name={['acf', 'customer_info', 'howfrom']} label="How find us">
				<Select options={referralS} />
			</Item>
			<Item name={['acf', 'customer_info', 'note']} label="Customer notes">
				<Input.TextArea rows={3} />
			</Item>
			<Item name={['acf', 'foreman_info', 'notes']} label="Notes for foreman">
				<Input.TextArea rows={3} />
			</Item>

			<Item name="status" hidden>
				<Input />
			</Item>
			<Item name={['acf', 'state']} hidden>
				<Input />
			</Item>
			<Item name={['author']} hidden>
				<InputNumber />
			</Item>
		</Form>
	);
};

