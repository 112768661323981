import React, { useState } from 'react';
import { Divider, Select } from 'antd';
import { userStore } from 'stores';
import { useUser } from 'hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ICompletedWork, IKeys, IWork, IWorkState, IWorkStatus } from 'models';
import { workService } from 'services';
import { observer } from 'mobx-react-lite';
import { MyCompletedWorks } from 'components/MyCompletedWorks';

interface IUpdateWork {
	data: Partial<IWork> | any,
	id: number
}

export const Salaries = observer(() => {
	const { helpersForemans, isUsersLoading } = useUser();
	const [userId, setUserId] = useState<undefined | number>(userStore.isAdmin ? undefined : userStore.data?.id);
	const { data, isLoading } = useQuery({
		queryKey: [IKeys.WORKS, { user: userId, state: IWorkState.COMPLETED }],
		queryFn: () => workService.getByUser(userId || 0, { state: IWorkState.COMPLETED }),
		enabled: !!userId
	});

	const { mutate } = useMutation({
		mutationFn: ({ data, id }: IUpdateWork) => workService.update(data, id)
	});

	const updateWork = (work: ICompletedWork, status: IWorkStatus) => {
		const data = {
			acf: {
				foreman_info: {
					workers: work.workers.map((el) => {
						if (el.worker.id === work.worker.worker.id) {
							return { ...el, worker: el.worker.id, status: !status };
						}
						return { ...el, worker: el.worker.id };
					})
				}
			}
		};
		mutate({ data, id: work.id });
	};
	return (
		<>
			{userStore.isAdmin && (
				<Select
					filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
					onSelect={setUserId}
					showSearch
					style={{ width: 300 }}
					placeholder="Enter name"
					size="large"
					options={helpersForemans.map(el => ({ value: el.id.toString(), label: el.name }))}
					loading={isUsersLoading}
				/>
			)
			}
			<Divider />
			<MyCompletedWorks
				isFetching={isLoading}
				updateWork={updateWork}
				data={data}
			/>
		</>
	);
});

