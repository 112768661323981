import React from 'react';
import { Button, Form, Input } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { userService } from 'services';
import { ILoginForm } from 'models';
import { userStore } from 'stores';

import logo from '../assets/logo-white.png';

export const Login = () => {
	const [form] = Form.useForm();
	const { mutate, isPending } = useMutation({
		mutationFn: userService.login,
		onSuccess: (data) => {
			userStore.setUser(data);
			form.resetFields();
		}
	});
	const onFinish = (data: ILoginForm) => {
		mutate(data);
	};

	return (
		<div className="login-form">
			<div className="login-form-container">
				<img src={logo} className="login-logo" alt="logo" />
				<Form<ILoginForm>
					name="login"
					onFinish={onFinish}
					layout="vertical"
					form={form}
					size="large"
					style={{ maxWidth: 400 }}
				>
					<Form.Item
						label="Username"
						name="username"
						rules={[
							{
								required: true,
								message: 'Please input your username!'
							}
						]}
					>
						<Input name="username" />
					</Form.Item>
					<Form.Item
						label="Password"
						name="password"
						rules={[
							{
								required: true,
								message: 'Please input your password!'
							}
						]}
					>
						<Input.Password name="password" />
					</Form.Item>
					<Form.Item>
						<Button
							loading={isPending}
							type="primary"
							htmlType="submit"
							block
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

