import { observer } from 'mobx-react-lite';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { userStore } from 'stores';
import { ErrorPage, Home } from 'pages';
import { useSwipeable } from 'react-swipeable';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useError } from 'hooks';
import { AxiosError } from 'axios';
import { IResponseError } from 'models';

import { privateRoutes, publicRoutes } from './routes/routes';
import { Template } from './components';

const App = observer(() => {
	const { onErrorFn, contextHolder } = useError();
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false
			}
		},
		queryCache: new QueryCache({
			onError: (error, query) => {
				onErrorFn(error as AxiosError<IResponseError>, query.meta?.errorMessage as string);
			}
		})
	});

	const routes = userStore.role ? privateRoutes(userStore.role) : publicRoutes;

	const router = createBrowserRouter([
		{
			path: '/',
			element: <Template />,
			errorElement: <ErrorPage />,
			children: [
				{
					path: '/',
					element: <Home />
				},
				...routes
			]
		}
	]);
	const handlers = useSwipeable({
		onSwipedDown: () => window.location.reload(),
		delta: 250
	});

	return (
		<QueryClientProvider client={queryClient}>
			<div {...handlers} className="app-container">
				<RouterProvider router={router} />
				{contextHolder}
			</div>
		</QueryClientProvider>
	);
});

export default App;