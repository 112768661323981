import { Button, DatePicker, Form, Input, InputNumber } from 'antd';
import { IWork, IWorkState } from 'models';
import dayjs from 'dayjs';
import { userStore } from 'stores';
import { useWorkMutation } from 'hooks';

const { Item, useForm } = Form;

interface CreateCustomerFormProps {
	closeModal: () => void,
	queryKey: any[]
}

export const CreateCustomerForm = ({ closeModal, queryKey }: CreateCustomerFormProps) => {
	const [form] = useForm();
	const { updateWork: { mutate, isPending }, contextHolder } = useWorkMutation({ queryKey, onSettled: () => closeModal() });

	const onFinish = (values: Partial<IWork>) => {
		if (values.acf) {
			values.acf.date = dayjs(values.acf.date).format('YYYYMMDD');
			mutate(values);
		}
	};

	return (
		<>
			<Form
				form={form}
				size={'large'}
				initialValues={{
					acf: { state: IWorkState.PENDING },
					status: 'publish',
					author: userStore.data?.id
				}}
				onFinish={onFinish}
			>
				<Item
					name={['acf', 'customer_info', 'customer_name']}
				>
					<Input placeholder="Customer name" />
				</Item>
				<Item
					name={['acf', 'customer_info', 'customer_email']}
					rules={[{ type: 'email', message: 'Email is wrong' }]}
					hasFeedback
				>
					<Input placeholder="Customer email" />
				</Item>
				<Item
					name={['acf', 'customer_info', 'customer_phone']}
					rules={[{ pattern: new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'), message: 'Phone is wrong' }, { max: 10, message: '' }, { min: 10, message: '' }]}
					hasFeedback
				>
					<Input placeholder="Customer phone" />
				</Item>
				<Item
					name={['acf', 'date']}
				>
					<DatePicker placeholder="Move day" minDate={dayjs()} />
				</Item>
				<Item name={['acf', 'state']} hidden><Input /></Item>
				<Item name="status" hidden><Input /></Item>
				<Item name="author" hidden><InputNumber /></Item>
				<Button loading={isPending} type={'primary'} htmlType="submit">Submit</Button>
			</Form>
			{contextHolder}
		</>
	);
};

