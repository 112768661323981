import { AllCompletedWorks, CompletedWork, Salaries, Schedule, CompleteWorkForm, Login, MyWorks } from 'pages';
import { IUserRoles } from 'models';
import { RouteObject } from 'react-router';

import {
	ALL_WORKS_ROUTE,
	COMPLETED_WORK_ROUTE,
	LOGIN,
	MY_WORKS_ROUTE,
	SALARIES_ROUTE,
	SCHEDULE_ROUTE,
	SINGLE_WORK_ROUTE
} from './consts';

const helperRoutes: RouteObject[] = [
	{
		path: SALARIES_ROUTE,
		element: <Salaries />
	},
	{
		path: MY_WORKS_ROUTE,
		element: <MyWorks />
	}
];

const foremanRoutes: RouteObject[] = [
	{
		path: SINGLE_WORK_ROUTE,
		element: <CompleteWorkForm />
	}
];

const adminRoutes: RouteObject[] = [
	{
		path: ALL_WORKS_ROUTE,
		element: <AllCompletedWorks />
	},
	{
		path: SCHEDULE_ROUTE,
		element: <Schedule />
	},
	{
		path: COMPLETED_WORK_ROUTE,
		element: <CompletedWork />
	}
];

export const publicRoutes: RouteObject[] = [
	{
		path: LOGIN,
		element: <Login />
	}
];

export const privateRoutes = (user_role: IUserRoles) => {
	let routes = [...helperRoutes];
	if (user_role !== IUserRoles.HELPER) {
		routes.push(...foremanRoutes);
	}
	if ([IUserRoles.ADMIN, IUserRoles.MANAGER].includes(user_role)) {
		routes.push(...adminRoutes);
	}
	return routes;
};