export interface IResponseError extends Error {
	code: string;
	data: {
		status: number
	};
	message: string;
}

export enum IKeys {
	WORKS = 'works',
	USERS = 'users'
}