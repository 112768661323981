import { ILoginForm, IUserResponse, IWorker } from 'models';

import { $api, $auth } from '../http';

const _fields = 'acf,id,slug,roles,name';

class UserService {
	async login(data: ILoginForm) {
		try {
			const res = await $auth<IUserResponse>('/jwt-auth/v1/token', { data });
			return res.data;
		} catch (error) {
			throw error;
		}
	}

	async getAll(params?: any) {
		try {
			const res = await $api.get<IWorker[]>('/wp/v2/users', { params: { ...params, _fields } });
			return res.data;
		} catch (error) {
			throw error;
		}
	}
};

export const userService = new UserService();