export const suppliesPrice = (item: string) => {
	switch (item) {
		case 'small':
			return 3;
		case 'medium':
			return 4;
		case 'wardrobe':
		case 'blankets':
			return 30;
		case 'wrap_paper':
		case 'bubble_wrap':
			return 40;
		default:
			return 0;
	}
};