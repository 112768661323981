import { Spin } from 'antd';
import { useUser } from 'hooks';
import { IUserRoles, IWork, IWorkStatus } from 'models';
import { formatPhoneNumber } from 'utils';

interface WorkTitleProps {
	work: IWork
}

export const WorkTitle = ({ work }: WorkTitleProps) => {
	const { getUser, isUsersLoading } = useUser();
	const { acf: { foreman_info, customer_info }, id, author } = work;
	const foreman = foreman_info?.workers?.find((el) => el.worker_role === IUserRoles.FOREMAN);
	const foremanName = foreman?.worker.name || getUser(Number(foreman?.worker))?.name;
	const isConfirmed = foreman_info?.status === IWorkStatus.CONFIRMED ? '❗️' : '';

	return (
		<Spin spinning={isUsersLoading}>
			<span>
				{isConfirmed}
				{foremanName && <><span className="foreman-name-title">{foremanName}</span> - </>}
				{id}
				{' '}
				{customer_info.movers ? `-${parseInt(customer_info.movers)}-` : ''}
				{' '}
				{customer_info.customer_name}
				{' '}
				{formatPhoneNumber(customer_info.customer_phone)} | {customer_info.howfrom || ''} | {getUser(author)?.name}
			</span>
		</Spin>
	);
};