import React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableProps, Tag } from 'antd';
import { MY_WORKS_ROUTE } from 'routes/consts';
import { ICompletedWork, IKeys, IWorkState } from 'models';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { userStore } from 'stores';
import { workService } from 'services';

const columns: TableProps<ICompletedWork>['columns'] = [
	{
		title: 'Order',
		dataIndex: 'id',
		key: 'id',
		render: id => <Link to={`${MY_WORKS_ROUTE}/${id}`}>{id}</Link>
	},
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
		render: date => dayjs(date).format('MM/DD')
	},
	{
		title: 'Time',
		dataIndex: 'time',
		key: 'time'
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		render: status => <Tag color={status === 'pending' ? 'orange' : 'green'}>{status}</Tag>
	}
];

export const WorkerWork = () => {
	const id = userStore.data?.id || 0;
	const { data, isLoading } = useQuery({
		queryKey: [IKeys.WORKS, { user: id, state: IWorkState.ASSIGN_WORKERS }],
		queryFn: () => workService.getByUser(id, { state: IWorkState.ASSIGN_WORKERS })
	});
	return (
		<Table
			loading={isLoading}
			rowKey={el => el.id}
			dataSource={data?.data.works}
			columns={columns}
		/>);
};

