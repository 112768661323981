import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { userStore } from 'stores';

import logo from '../assets/logo-white.png';

import { AppMenu } from './AppMenu';

export const Template = observer(() => {
	return (
		<Layout className="main">
			{userStore.isAuth && (
				<Layout.Header className="site-header">
					<img src={logo} className="white-logo" alt="logo" />
					<AppMenu />
				</Layout.Header>
			)}
			<Layout.Content style={{ padding: 16 }}>
				<Outlet />
			</Layout.Content>
		</Layout>
	);
});
